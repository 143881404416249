:root {
  --primary-color: #007bff;
  --background-gradient: linear-gradient(to bottom, rgb(61, 7, 7), rgb(24, 14, 14));
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  max-width: 90%;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
  color: white !important;
}

.navbar {
  color: white !important;
  border-radius: 10px !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

.custom-navbar {
  color: white !important;
}

.custom-navbar .navbar-toggler-icon {
  color: white !important;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5) !important;
  color: #fff;
  padding: 10px 0;
  text-align: center;
}

.footer p {
  margin-bottom: 0;
}

.minecraft-card {
  background-color: var(--background-color) !important;
  background: rgba(0, 0, 0, 0.4) !important;
  border-radius: 10px !important;
  transition: box-shadow 0.25s !important;
  color: white !important;
}

body {
  min-height: 100%;
  background: var(--background-gradient);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #fff;
}


.navbar-toggler:focus {
  box-shadow: none !important;
}

.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.guild-card {
  background-color: var(--background-color) !important;
  background: rgba(0, 0, 0, 0.4) !important;
  border-radius: 10px !important;
  transition: box-shadow 0.25s !important;
  color: white !important;
}

.custom-guild-card {
  margin-bottom: 20px !important;
}

.server-card {
  background-color: var(--background-color) !important;
  background: rgba(0, 0, 0, 0.4) !important;
  border-radius: 10px !important;
  transition: box-shadow 0.25s !important;
  color: white !important;
}

.server-card-text {
  margin-top: 20px;
  white-space: nowrap;
}

.status {
  margin-top: 12px;
  margin-bottom: 6px;
}

input {
  background: #4f4d4d !important;
  color: #fff !important;
}

input::placeholder {
  color: #fff !important;
}

select {
  background: #4f4d4d !important;
  color: #fff !important;
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

.hero-section {
  background: rgba(0, 0, 0, 0.2) !important;
  padding: 100px 0;
  margin-top: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hero-content {
  margin-bottom: 40px;
}

.section {
  margin-bottom: 50px;
}

.img-fluid {
  max-width: 50% !important;
  height: auto;
}

.info-block {
  padding: 30px;
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.status-block {
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  height: 100px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 150px !important;
}

.progress {
  width: 100%;
}

.info-block h3 {
  margin-bottom: 20px;
}

.info-block p {
  color: #ffffff;
}

.info-text {
  margin-top: 20px;
}

.info-section {
  margin-bottom: 20px;
}

.info-section strong {
  display: block;
  font-weight: bold;
}

.info-section p {
  margin-top: 5px;
  margin-left: 20px;
}

.link {
  color: inherit;
  text-decoration: none;
}

.custom-button {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.sidebar {
  padding: 15px;
  background: rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

.modal-content {
  background: var(--background-gradient);
  color: #ffffff;
}

.status-block {
  position: relative;
  min-width: 170px !important;
}

.chart{
  max-height: 200px;
}

.status-icon-container {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 20px;
}


.status-icon {
  font-size: 40px;
}

.status-count {
  margin-bottom: 5px;
}

.card-status-text {
  margin-left: 10px;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}